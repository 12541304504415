import * as React from 'react'
import { useParams } from 'react-router-dom';
import { Team, Player, Contract, PlayerPositionEnum, ContractStatus } from '../../../sdk/model';
import MaterialReactTable from 'material-react-table';
import {Box, Typography} from '@mui/material';
import { toSalary } from '../../util/SalaryDisplay';
import ContractDetails from '../contract/ContractDetails';
import { contractApi } from '../../../adapters/APIExporter';
import TeamDisplay from '../team/TeamDisplay'
import PlayerCardLink from '../../cards/playerCardLink';

const positionOrder: Array<PlayerPositionEnum> = [PlayerPositionEnum.Qb,
                                                  PlayerPositionEnum.Rb,
                                                  PlayerPositionEnum.Wr,
                                                  PlayerPositionEnum.Te
                                                  ];

interface deadCapReportProps {
  deadCapContractIds: string[]
  teamId: string,
  currentSeason: number,
  teamMap: Map<string | undefined, Team | undefined> | undefined,
  playerMap: Map<string | undefined, Player | undefined>
}

function DeadCapReport(props: deadCapReportProps) {
  const {leagueId} = useParams();
  const [deadCapContracts, setDeadCapContracts] = React.useState<Contract[]>([]);
  
  React.useEffect(() => {
    contractApi.getContractsByIds(props.deadCapContractIds).then(response => {
      setDeadCapContracts(response.data.filter(contract => contract.playerId));
    })
  }, []);
  
  if (deadCapContracts.length === 0) {
    return <p>Loading...</p>
  }
  const columns = [
      {
        accessorKey: 'position',
        header: 'Position',
        size: 5,
        sortingFn: (rowA, rowB) => {
          return positionOrder.indexOf(rowA.getValue('position')) - positionOrder.indexOf(rowB.getValue('position'));
          }
      },
      {
        accessorKey: 'name',
        header: 'Player Name',
        size: 50,
        Cell: ({cell, row}) => (
          <PlayerCardLink player={row.original.player} leagueId={leagueId!} />
        )
      },
      {
        accessorKey: 'capHit',
        header: 'Cap Hit in ' + props.currentSeason,
        size: 2,
        Cell : ({cell}) => (
          cell.getValue() ? toSalary(cell.getValue()) : '$0'
        )
      },
      {
        accessorKey: 'capHitNextYear',
        header: 'Cap Hit in ' + (props.currentSeason + 1),
        size: 2,
        Cell : ({cell}) => (
          cell.getValue() ? toSalary(cell.getValue()) : '$0'
        )
      },
      {
        accessorKey: 'status',
        header: 'Current Status',
        size: 50,
      },
    ]
  
  const data = deadCapContracts.map(contract => {
    const player: Player = props.playerMap.get(contract.playerId)!;
    return {
      position: player.position,
      name: player.abbr_name,
      player: player,
      contract: contract,
      capHit: contract.seasons!.find(season => season.season_year === props.currentSeason)?.weeks?.reduce((seasonSum, week) => 
        seasonSum + (week.payments?.filter(payment => props.teamId === payment.teamId)?.reduce((weeklySum, payment) => weeklySum + (payment?.price || 0), 0) || 0)
      , 0) || 0,
      capHitNextYear: contract.seasons!.find(season => season.season_year === props.currentSeason + 1)?.weeks?.reduce((seasonSum, week) => 
        seasonSum + (week.payments?.filter(payment => props.teamId === payment.teamId)?.reduce((weeklySum, payment) => weeklySum + (payment?.price || 0), 0) || 0)
      , 0) || 0,
      status: ContractStatus.Active === contract.status ? <React.Fragment>Signed to <TeamDisplay leagueId={leagueId!} team={props.teamMap?.get(contract.teamId)!} emphasized={false} /></React.Fragment> : contract.status
    }
  });
  
  return <Box>
    <Typography variant="h5">
      Dead Cap:
    </Typography>
    <MaterialReactTable columns={columns} data={data} enableFilters={false} enableColumnActions={false} enablePagination={false} initialState={{
        density: 'compact',
        sorting: [
          {
            id: 'position',
            desc: false
          },
          {
            id: 'capHit',
            desc: true
          }
        ]
      }} renderDetailPanel={({row}) =><ContractDetails leagueId={leagueId!} player={row.original.player} contract={row.original.contract!} />} />;
  </Box>
}

export default DeadCapReport;