import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { Typography, TextField, Button, IconButton } from '@mui/material';
import { teamApi } from '../../../adapters/APIExporter';

function TeamSettings({league}) {
  const {teamId} = useParams();
  const navigate = useNavigate();
  const team = league?.teams.find(team => team.id === teamId);
  const [mutableTeam, setMutableTeam] = React.useState({...team});
  const [editMode, setEditMode] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  function handleTeamChange(event) {
  	const {name, value} = event.target;
  	const newTeam = {...mutableTeam};
  	newTeam[name] = value;
  	setMutableTeam(newTeam);
  }
  const validateTeamName = (value, errors) => {
    if (value.length < 3 || value.length > 25) {
      errors.push('You must provide a Team Name with a length between 3 and 25 characters');
    }
  }
  function cancelEdit() {
	setMutableTeam({...team});
	setEditMode(false);
  }
  function saveTeamSettings() {
	let newErrors = [];
    validateTeamName(mutableTeam.name, newErrors);
    setErrors(newErrors);
    if (newErrors.length > 0) {
	  return;
    }
	setEditMode(false);
	teamApi.updateTeam(mutableTeam.id, mutableTeam)
	  .then()
      .catch((error) => {
        newErrors.push(error.response.data.message);
        setErrors(newErrors);
        setEditMode(true);
  	  });
  }
  function leaveTeam() {
    teamApi.leaveTeam(teamId).then(() => {
      navigate('/league/' + league?.id);
    })
  }
  let errorInfo = errors.map((error, index) => {
    return (<p className='error' key={'error_' + index}>{error}</p>);
  });
  if (!teamId) {
    return;
  }
  return (
    <Box>
      <Typography variant="h6" component="div" color="text.secondary" sx={{ flexGrow: 1 }}>
        {team.name} Settings
      </Typography>
      <TextField name='name' label="Team Name" value={mutableTeam.name}
          disabled={!editMode} onChange= {handleTeamChange}/>
      <br/>
      {editMode && <TextField name='picture' label="Image URL" value={mutableTeam.picture} onChange={handleTeamChange}/>}
      <IconButton sx={{ p: 0 }}>
        <Avatar alt={mutableTeam.name} src={mutableTeam.picture}>{!mutableTeam.picture && mutableTeam.name.substring(0,1)}</Avatar>
      </IconButton>
      <br/>
      {editMode ?
        <Box>
          <Button className='capsized-button' onClick={cancelEdit}>Cancel</Button>
          <Button className='capsized-button' onClick={saveTeamSettings}>Save Team Settings</Button>
        </Box>
        : <Box>
          <Button className='capsized-button' onClick={() => setEditMode(true)}>Edit Team Settings</Button><br/>
          <Button className='capsized-button' onClick={leaveTeam}>Leave Team</Button>
        </Box>
      }
      {errorInfo}
    </Box>
  )
}

export default TeamSettings;